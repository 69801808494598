import { useTranslation } from 'next-i18next';

export default function Maintenance() {
    const { t } = useTranslation('common');

    return (
        <div style={{ textAlign: 'center', marginTop: '20%' }}>
            <h1>{t('maintenance.title')}</h1>
            <p>{t('maintenance.description')}</p>
        </div>
    );
}